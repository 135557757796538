import * as React from 'react';
import {Helmet} from 'react-helmet';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS, INLINES} from '@contentful/rich-text-types';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col, Image} from 'react-bootstrap';
import {useIntl} from 'gatsby-plugin-intl';

const KonferencjaSinglePage = ({pageContext}) => {
  const {locale} = useIntl();

  const konferencja = pageContext.konferencja;

  const data =
    locale === 'pl'
      ? {title: konferencja.title.pl, text: konferencja.text.pl}
      : {title: konferencja.title.en || konferencja.title.pl, text: konferencja.text.en || konferencja.text.pl};

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const {title, file} = node.data.target.fields;
        return <Image src={`https:${file.pl.url}`} alt={title} fluid rounded />;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
    },
  };

  return (
    <Layout>
      <Helmet>
        <title>{data.title} – Aktualności – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`${konferencja.title.pl} – Aktualności – Studenckie Koła Naukowe AGH`} />
        {konferencja.image && <meta property="og:image" content={`https:${konferencja.image}`} />}
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />

      <div className="news-single-page pt-5">
        <Container className="py-xl">
          <Row>
            <Col lg={10} xl={8}>
              <h1>{data.title}</h1>
              <span>{konferencja.date}</span>
              {konferencja.image && <Image src={konferencja.image} fluid rounded />}
              <div className="news-single-page__content">{documentToReactComponents(data.text, options)}</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export default KonferencjaSinglePage;
